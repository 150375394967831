import { BaseApi } from "@/api/base-api";
import { RESOURCE_PROVISIONS } from "@/shared/constants/resources";

class ProvisionsApi extends BaseApi {
  constructor(resource) {
    super(resource);
    this.resource = resource;
  }
}

export default new ProvisionsApi(RESOURCE_PROVISIONS);
