<template>
   <BaseCard
    title="Configuracion"
    :footer-visible="false"
    :loading="loading"
    collapsable
    :initial-collapse="false"
  >
  <ConfigurationAditionalForm  
    ref="configuration-aditional-form" 
    v-model="configuration" 
  />
  <b-row
      class="px-2 pb-2"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          class="rounded-sm px-2"
          @click="handleSaveButtonClick"
        >
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </BaseCard>
</template>

<script>
import ConfigurationApi from '@/api/configuration-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import ConfigurationAditionalForm from '@/components/aditional-configuration/form/ConfigurationAditionalForm.vue'

export default {
  components: { BaseCard, ConfigurationAditionalForm },
  data() {
    return {
      configuration: {},
      loading: false,
    }
  },
  mounted() {
    this.loadCompany()
  },
  methods: {
    async loadCompany() {
      this.loading = true
      try {
        const response = await ConfigurationApi.getCompanyConfiguration()
        this.configuration = response.data
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      this.loading = true
      try {
        await ConfigurationApi.editCompanyConfiguration(this.configuration)
      } finally {
        this.loading = false
      }
    },
  },
}

</script>
