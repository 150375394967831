<template>
  <BaseCard
    title="Tipos de pago"
    :footer-visible="false"
    actions-visible
    collapsable
    :initial-collapse="false"
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir tipo de pago</u>
      </b-link>
    </template>
    <TypePaymentsTable ref="type-payments-table" />
    <TypePaymentsCreationModal
      v-model="creationModalVisible"
      @type-payment-creation="typePaymentsTable.loadTypePayments()"
    />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import TypePaymentsTable from '@/components/type-payments/table/TypePaymentsTable.vue'
import TypePaymentsCreationModal from '@/components/type-payments/modal/TypePaymentsCreationModal.vue'

export default {
  name: 'ConfigurationTypePaymentsCard',
  components: { TypePaymentsCreationModal, TypePaymentsTable, BaseCard },
  data() {
    return {
      creationModalVisible: false,
    }
  },
  computed: {
    typePaymentsTable() {
      return this.$refs['type-payments-table']
    },
  },
}
</script>

<style scoped>
</style>
