<template>
  <validation-observer ref="configuration-aditional-form">
    <b-row class="mx-2 my-2">
      <b-col lg="6">
        <b-form-group label="Coste técnico propio" label-for="own_technical_cost">
          <b-form-input
            id="own_technical_cost"
            :value="model.own_technical_cost"
            placeholder="Coste técnico propio"
            type="number"
            @input="update('own_technical_cost', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Coste técnico subcontrata" label-for="technical_cost_hire">
          <b-form-input
            id=" technical_cost_hire"
            :value="model.technical_cost_hire"
            placeholder="Coste técnico subcontrata"
            type="number"
            @input="update('technical_cost_hire', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Coste alojamiento" label-for="accommodation_cost">
          <b-form-input
            id="accommodation_cost"
            :value="model.accommodation_cost"
            type="number"
            placeholder="Coste alojamiento"
            @input="update('accommodation_cost', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Coste dieta con alojamiento" label-for="diet_cost_with_accommodation">
          <b-form-input
            id="diet_cost_with_accommodation"
            :value="model.diet_cost_with_accommodation"
            type="number"
            placeholder="Coste dieta con alojamiento"
            @input="update('diet_cost_with_accommodation', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Coste dieta sin alojamiento" label-for="diet_cost_without_accommodation">
          <b-form-input
            id="diet_cost_without_accommodation"
            :value="model.diet_cost_without_accommodation"
            type="number"
            placeholder="Coste dieta sin alojamiento"
            @input="update('diet_cost_without_accommodation', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import FormMixin from "@/mixins/formMixin";

export default {
  name: "ConfigurationAditionalForm",
  mixins: [FormMixin],
  methods: {
    validateForm() {
      return this.$refs["configuration-aditional-form"].validate();
    },
  },
};
</script>
