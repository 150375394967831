<template>
  <BaseCard
    title="cuentas bancarias"
    :footer-visible="false"
    actions-visible
    collapsable
    :initial-collapse="false"
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir cuenta</u>
      </b-link>
    </template>
    <BankAccountsTable ref="bank-account-table" />
    <BankAccountCreationModal
      v-model="creationModalVisible"
      @bank-account-creation="bankAccountsTable.loadBankAccounts()"
    />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import BankAccountCreationModal from '@/components/bank-accounts/modal/BankAccountCreationModal.vue'
import BankAccountsTable from '@/components/bank-accounts/table/BankAccountsTable.vue'

export default {
  name: 'ConfigurationBankAccountsCard',
  components: { BankAccountsTable, BankAccountCreationModal, BaseCard },
  data() {
    return {
      creationModalVisible: false,
    }
  },
  computed: {
    bankAccountsTable() {
      return this.$refs['bank-account-table']
    },
  },
}
</script>

<style scoped>
</style>
