<template>
  <b-modal
    v-model="model"
    size="lg"
    hide-footer
    centered
  >
    <h1 class="font-large-1 text-center pt-2">
      Añadir Impuesto
    </h1>
    <validation-observer ref="configuration-tax-form">
      <b-form
        class="py-2"
        @submit.prevent
      >
        <b-row align-h="center">
          <b-col
            sm="12"
            lg="10"
          >
            <validation-provider
              v-slot="validationContext"
              name="Nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre"
                label-for="tax-name"
              >
                <b-form-input
                  id="tax-name"
                  v-model="name"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            sm="12"
            lg="10"
          >
            <validation-provider
              v-slot="validationContext"
              name="Tipo"
              rules="required"
            >
              <b-form-group
                label="Tipo"
                label-for="tax-type"
              >
                <BaseSelect
                  id="tax-type"
                  v-model="type"
                  :options="[{ name: 'IVA', value: 'iva' }, { name: 'IGIC', value: 'igic' }]"
                  label="name"
                  :reduce="item => item.value"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            sm="12"
            lg="10"
          >
            <b-form-group
              label="Descripción"
              label-for="tax-description"
            >
              <b-form-input
                id="tax-description"
                v-model="description"
              />
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <validation-provider
              v-slot="validationContext"
              name="Porcentaje"
              rules="required"
            >
              <b-form-group
                label="Porcentaje"
                label-for="tax-percent"
              >
                <b-form-input
                  id="tax-percent"
                  v-model="percent"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="5">
            <b-form-checkbox
              v-model="taxDefault"
              class="mt-2"
              :value="1"
              :unchecked-value="0"
            >
              Por defecto
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <b-row align-h="center">
        <b-col cols="auto">
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm px-2"
            @click="handleAcceptButtonClick"
          >
            Aceptar
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import ApiRestService from '@/api/base-api'

export default {
  name: 'TaxesCreationModal',
  components: { BaseSelect },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      description: '',
      percent: 0,
      taxDefault: 0,
      type: '',
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async handleAcceptButtonClick() {
      const valid = await this.$refs['configuration-tax-form'].validate()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.create(
          {
            name: this.name,
            description: this.description,
            percent: this.percent,
            default: this.taxDefault,
            type: this.type,
          },
          this.$data.$constants.RESOURCES.RESOURCE_TAXES,
        )
        this.$emit('input', false)
        this.$emit('tax-creation')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
