<template>
  <b-overlay
    :show="loading"
    variant="white"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-table
      hover
      responsive
      :items="typePayments"
      :fields="fields"
      :no-local-sorting="true"
    >
      <template #cell(name)="data">
        <b-form-input
          v-model="data.item.name"
          @change="handleTypePaymentUpdate(data.item)"
        />
      </template>
      <template #cell(description)="data">
        <b-form-input
          v-model="data.item.description"
          @change="handleTypePaymentUpdate(data.item)"
        />
      </template>
      <template #cell(days)="data">
        <b-form-input
          v-model="data.item.days"
          type="number"
          @change="handleTypePaymentUpdate(data.item)"
        />
      </template>
      <template #cell(invoices_enabled)="data">
        <b-form-checkbox
          v-model="data.item.invoices_enabled"
          :value="1"
          :unchecked-value="0"
          @change="handleTypePaymentUpdate(data.item)"
        />
      </template>
      <template #cell(payments_enabled)="data">
        <b-form-checkbox
          v-model="data.item.payments_enabled"
          :value="1"
          :unchecked-value="0"
          @change="handleTypePaymentUpdate(data.item)"
        />
      </template>
      <template #cell(actions)="data">
        <b-link @click="handleDeleteButtonClick(data.item)">
          <span class="d-inline-block px-50 text-danger">
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </b-link>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import ApiRestService from '@/api/base-api'

export default {
  name: 'TypePaymentsTable',
  data() {
    return {
      loading: false,
      typePayments: [],
      fields: [
        {
          label: 'Nombre',
          key: 'description',
          sortable: false,
          thStyle: { 'min-width': '220px' },
        },
        {
          label: 'Dias',
          key: 'days',
          sortable: false,
        },
        {
          label: 'Descripción',
          key: 'name',
          sortable: false,
          thStyle: { 'min-width': '220px' },
        },
        {
          label: 'Facturas habilitadas',
          key: 'invoices_enabled',
          sortable: false,
        },
        {
          label: 'Pagos habilitados',
          key: 'payments_enabled',
          sortable: false,
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  mounted() {
    this.loadTypePayments()
  },
  methods: {
    async loadTypePayments() {
      this.loading = true
      try {
        const response = await ApiRestService.list({}, this.$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS)
        this.typePayments = response.data
      } finally {
        this.loading = false
      }
    },
    async handleTypePaymentUpdate(typePayment) {
      this.loading = true
      try {
        await ApiRestService.edit(typePayment.id, typePayment, this.$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteButtonClick(typePayment) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(typePayment.id, this.$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS)
        await this.loadTypePayments()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
