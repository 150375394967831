import Vue from 'vue'

const RESOURCE_NAME = '/configuration'

export default {
  editCompanyConfiguration(configuration) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${RESOURCE_NAME}/company`, configuration)
        resolve(response.data)
        Vue.$toast('Recurso actualizado con éxito.')
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  },
  getCompanyConfiguration() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get(`${RESOURCE_NAME}/company`)
        resolve(response)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener el recurso. Por favor inténtelo de nuevo mas tarde.')
        console.error(error)
        reject(error)
      }
    })
  },
}
