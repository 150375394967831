<template>
  <BaseCard
    title="tipologías de factura"
    :footer-visible="false"
    actions-visible
    collapsable
    :initial-collapse="false"
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir tipología de factura</u>
      </b-link>
    </template>
    <SaleInvoiceTypesTable ref="sale-invoice-types-table" />
    <SaleInvoiceTypeCreationModal
      v-model="creationModalVisible"
      @sale-invoice-type-creation="$refs['sale-invoice-types-table'].loadSaleInvoiceTypes()"
    />
  </BaseCard>
</template>

<script>
import SaleInvoiceTypesTable from '@/components/sale-invoices/table/SaleInvoiceTypesTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import SaleInvoiceTypeCreationModal from '@/components/sale-invoices/modal/SaleInvoiceTypeCreationModal.vue'

export default {
  name: 'ConfigurationSaleInvoiceTypesCard',
  components: { SaleInvoiceTypeCreationModal, BaseCard, SaleInvoiceTypesTable },
  data() {
    return {
      creationModalVisible: false,
    }
  },
}
</script>

<style scoped>

</style>
