<template>
  <b-overlay
    :show="loading"
    variant="white"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-table
      hover
      responsive
      :items="taxes"
      :fields="fields"
      :no-local-sorting="true"
    >
      <template #cell(name)="data">
        <b-form-input
          v-model="data.item.name"
          @change="handleTaxUpdate(data.item)"
        />
      </template>
      <template #cell(description)="data">
        <b-form-input
          v-model="data.item.description"
          @change="handleTaxUpdate(data.item)"
        />
      </template>
      <template #cell(percent)="data">
        <b-form-input
          v-model="data.item.percent"
          @change="handleTaxUpdate(data.item)"
        />
      </template>
      <template #cell(default)="data">
        <b-form-checkbox
          v-model="data.item.default"
          :value="1"
          :unchecked-value="0"
          @change="handleTaxUpdate(data.item)"
        />
      </template>
      <template #cell(type)="data">
        <BaseSelect
          id="tax-type"
          v-model="data.item.type"
          :options="[{ name: 'IVA', value: 'iva' }, { name: 'IGIC', value: 'igic' }]"
          label="name"
          :reduce="item => item.value"
          @selected="handleTaxUpdate(data.item)"
        />
      </template>
      <template #cell(actions)="data">
        <b-link @click="handleDeleteButtonClick(data.item)">
          <span class="d-inline-block px-50 text-danger">
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </b-link>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import ApiRestService from '@/api/base-api'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'TaxesTable',
  components: { BaseSelect },
  data() {
    return {
      loading: false,
      taxes: [],
      fields: [
        {
          label: 'Nombre',
          key: 'name',
          sortable: false,
          thStyle: { 'min-width': '220px' },
        },
        {
          label: 'Descripción',
          key: 'description',
          sortable: false,
          thStyle: { 'min-width': '220px' },
        },
        {
          label: 'Porcentaje',
          key: 'percent',
          sortable: false,
        },
        {
          label: 'Por defecto',
          key: 'default',
          sortable: false,
        },
        {
          label: 'Tipo',
          key: 'type',
          sortable: false,
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  mounted() {
    this.loadTaxes()
  },
  methods: {
    async loadTaxes() {
      this.loading = true
      try {
        const response = await ApiRestService.list({}, this.$data.$constants.RESOURCES.RESOURCE_TAXES)
        this.taxes = response.data
      } finally {
        this.loading = false
      }
    },
    async handleTaxUpdate(tax) {
      this.loading = true
      try {
        await ApiRestService.edit(tax.id, tax, this.$data.$constants.RESOURCES.RESOURCE_TAXES)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteButtonClick(tax) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(tax.id, this.$data.$constants.RESOURCES.RESOURCE_TAXES)
        await this.loadTaxes()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
