<template>
  <b-overlay
    :show="loading"
    variant="white"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-table
      ref="provisions-table"
      hover
      responsive
      :items="provisions"
      :fields="fields"
      :no-local-sorting="true"
    >
      <template #thead-top="data">
        <b-tr>
          <b-th colspan="2"></b-th>
          <b-th colspan="3">Minutos</b-th>
          <b-th colspan="3">Horas</b-th>
        </b-tr>
      </template>
      <template #cell(name)="data">
        <b-form-input
          v-model="data.item[data.field.key]"
          @change="handleProvisionUpdate(data.item)"
        />
      </template>
      <template #cell(trimestral_minutes)="data">
        <b-form-input
          v-model="data.item[data.field.key]"
          @change="handleProvisionUpdate(data.item)"
        />
      </template>
      <template #cell(semestral_minutes)="data">
        <b-form-input
          v-model="data.item[data.field.key]"
          @change="handleProvisionUpdate(data.item)"
        />
      </template>
      <template #cell(trimestral_hours)="data">
        <b-form-input
          v-model="data.item[data.field.key]"
          @change="handleProvisionUpdate(data.item)"
          disabled
        />
      </template>
      <template #cell(semestral_hours)="data">
        <b-form-input
          v-model="data.item[data.field.key]"
          @change="handleProvisionUpdate(data.item)"
          disabled
        />
      </template>
      <template #cell(anual_hours)="data">
        <b-form-input
          v-model="data.item[data.field.key]"
          @change="handleProvisionUpdate(data.item)"
          disabled
        />
      </template>
      <template #cell(anual_minutes)="data">
        <b-form-input
          v-model="data.item[data.field.key]"
          @change="handleProvisionUpdate(data.item)"
        />
      </template>
      <template #cell(category)="data">
        <BaseSelect
          id="category"
          :value="data.item.provision_category"
          :resource="$data.$constants.RESOURCES.RESOURCE_PROVISION_CATEGORIES"
          :clearable="false"
          @input="data.item.provision_category = $event"
          @change="handleProvisionUpdate(data.item)"
        />
      </template>
      <template #cell(actions)="data">
        <b-link>
          <span
            class="d-inline-block px-50 text-danger"
            @click="handleDeleteButtonClick(data.item)"
          >
            <feather-icon
              v-b-tooltip.hover
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </b-link>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import ApiRestService from "@/api/base-api";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";

export default {
  name: "ProvisionsTable",
  components: {
    BaseSelect,
  },
  data() {
    return {
      loading: false,
      provisions: [],
      fields: [
        {
          label: "Categoría",
          key: "category",
          sortable: false,
          thStyle: { 'width': '400px' },
        },
        {
          label: "Nombre",
          key: "name",
          sortable: false,
        },
        {
          label: "Trimestral",
          key: "trimestral_minutes",
          sortable: false,
        },
        {
          label: "Semestral",
          key: "semestral_minutes",
          sortable: false,
        },
        {
          label: "Anual",
          key: "anual_minutes",
          sortable: false,
          thStyle: { 'width': '100px' },
        },
        {
          label: "Trimestral",
          key: "trimestral_hours",
          sortable: false,
        },
        {
          label: "Semestral",
          key: "semestral_hours",
          sortable: false,
        },
        {
          label: "Anual",
          key: "anual_hours",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.loadProvisions();
  },
  methods: {
    async loadProvisions() {
      this.loading = true;
      try {
        const response = await ApiRestService.list(
          {},
          this.$data.$constants.RESOURCES.RESOURCE_PROVISIONS
        );
        this.provisions = response.data;
      } finally {
        this.loading = false;
      }
    },
    async handleProvisionUpdate(provision) {
      if (!provision) {
        return;
      }

      this.loading = true;
      try {
        await ApiRestService.edit(
          provision.id,
          provision,
          this.$data.$constants.RESOURCES.RESOURCE_PROVISIONS
        );
        await this.loadProvisions();
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteButtonClick(provision) {
      if (!provision) {
        return;
      }

      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "Se borrará el registro.",
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await ApiRestService.delete(
          provision.id,
          this.$data.$constants.RESOURCES.RESOURCE_PROVISIONS
        );
        await this.loadProvisions();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
