<template>
  <b-modal
    v-model="model"
    size="lg"
    hide-footer
    centered
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <h1 class="font-large-1 text-center pt-2">
        Añadir cuenta bancaria
      </h1>
      <validation-observer ref="configuration-bank-account-form">
        <b-form
          class="py-2"
          @submit.prevent
        >
          <b-row align-h="center">
            <b-col
              sm="12"
              lg="10"
            >
              <validation-provider
                v-slot="validationContext"
                name="Cuenta"
                rules="required"
              >
                <b-form-group
                  label="Cuenta"
                  label-for="company-account"
                >
                  <b-form-input
                    id="company-account"
                    v-model="name"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleAcceptButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import ApiRestService from '@/api/base-api'

export default {
  name: 'BankAccountCreationModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      loading: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async handleAcceptButtonClick() {
      const valid = await this.$refs['configuration-bank-account-form'].validate()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.create({ name: this.name }, this.$data.$constants.RESOURCES.RESOURCE_BANK_ACCOUNTS)
        this.$emit('input', false)
        this.$emit('bank-account-creation')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
