<template>
  <BaseCard
    title="Impuestos"
    :footer-visible="false"
    actions-visible
    collapsable
    :initial-collapse="false"
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir impuesto</u>
      </b-link>
    </template>
    <TaxesTable ref="taxes-table" />
    <TaxesCreationModal
      v-model="creationModalVisible"
      @tax-creation="taxesTable.loadTaxes()"
    />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import TaxesCreationModal from '@/components/tax/modal/TaxesCreationModal.vue'
import TaxesTable from '@/components/tax/table/TaxesTable.vue'

export default {
  name: 'ConfigurationTaxesCard',
  components: { TaxesTable, TaxesCreationModal, BaseCard },
  data() {
    return {
      creationModalVisible: false,
    }
  },
  computed: {
    taxesTable() {
      return this.$refs['taxes-table']
    },
  },
}
</script>

<style scoped>
</style>
