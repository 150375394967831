<template>
  <b-modal
    v-model="model"
    size="lg"
    hide-footer
    centered
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <h1 class="font-large-1 text-center pt-2">
        Añadir tipo de pago
      </h1>
      <validation-observer ref="configuration-type-payment-form">
        <b-form
          class="py-2"
          @submit.prevent
        >
          <b-row align-h="center">
            <b-col
                sm="12"
                lg="10"
            >
              <b-form-group
                  label="Nombre"
                  label-for="type-payment-description"
              >
                <b-form-input
                    id="type-payment-description"
                    v-model="form.description"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              lg="10"
            >
              <validation-provider
                v-slot="validationContext"
                name="Descripción"
                rules="required"
              >
                <b-form-group
                  label="Descripción"
                  label-for="type-payment-name"
                >
                  <b-form-input
                    id="type-payment-name"
                    v-model="form.name"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              sm="12"
              lg="10"
            >
              <b-form-group
                label="Dias"
                label-for="type-payment-days"
              >
                <b-form-input
                  id="type-payment-days"
                  v-model="form.days"
                />
              </b-form-group>
            </b-col>
            <b-col cols="5">
              <b-form-checkbox
                v-model="form.invoices_enabled"
                :value="1"
                :unchecked-value="0"
              >
                Facturas hablitadas
              </b-form-checkbox>
            </b-col>
            <b-col cols="5">
              <b-form-checkbox
                v-model="form.payments_enabled"
                :value="1"
                :unchecked-value="0"
              >
                Pagos hablitados
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleAcceptButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import ApiRestService from '@/api/base-api'

export default {
  name: 'TypePaymentsCreationModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        name: '',
        days: null,
        description: '',
        invoices_enabled: 0,
        payments_enabled: 0,
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async handleAcceptButtonClick() {
      const valid = await this.$refs['configuration-type-payment-form'].validate()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.create(this.form, this.$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS)
        this.$emit('input', false)
        this.$emit('type-payment-creation')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
