<template>
  <b-modal
    v-model="model"
    size="lg"
    hide-footer
    centered
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <h1 class="font-large-1 text-center pt-2">
        Añadir cuenta contable
      </h1>
      <validation-observer ref="configuration-accounting-account-form">
        <b-form
          class="py-2"
          @submit.prevent
        >
          <b-row align-h="center">
            <b-col
              sm="10"
              lg="7"
            >
              <validation-provider
                v-slot="validationContext"
                name="Orden"
                rules="required"
              >
                <b-form-group
                  label="Orden"
                  label-for="order"
                >
                  <b-form-input
                    id="order"
                    type="number"
                    v-model="order"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
              <b-col
                sm="10"
                lg="7"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="Cuenta"
                  rules="required"
                >
                  <b-form-group
                    label="Cuenta"
                    label-for="company-account"
                  >
                    <b-form-input
                      id="company-account"
                      v-model="name"
                    />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
          </b-row>
          </b-row>
        </b-form>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleAcceptButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import ApiRestService from '@/api/base-api'

export default {
  name: 'AccountingAccountCreationModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      order: null,
      loading: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async handleAcceptButtonClick() {
      const valid = await this.$refs['configuration-accounting-account-form'].validate()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        const params = { name: this.name, order: this.order }
        await ApiRestService.create(params, this.$data.$constants.RESOURCES.RESOURCE_ACCOUNTING_ITEMS)
        this.$emit('input', false)
        this.$emit('created')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>