<template>
  <BaseCard
    title="datos de la empresa"
    :footer-visible="false"
    :loading="loading"
    collapsable
    :initial-collapse="false"
  >
    <ConfigurationCompanyProfileForm
      ref="configuration-company-profile-form"
      v-model="configurationCompanyProfile"
    />
    <b-row
      class="px-2 pb-2"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          class="rounded-sm px-2"
          @click="handleSaveButtonClick"
        >
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </BaseCard>
</template>

<script>
import ConfigurationApi from '@/api/configuration-api'
import ConfigurationCompanyProfileForm from '@/components/configuration/form/ConfigurationCompanyProfileForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  name: 'ConfigurationCompanyProfileCard',
  components: { BaseCard, ConfigurationCompanyProfileForm },
  data() {
    return {
      configurationCompanyProfile: {},
      loading: false,
    }
  },
  computed: {
    configurationCompanyProfileForm() {
      return this.$refs['configuration-company-profile-form']
    },
  },
  mounted() {
    this.loadCompany()
  },
  methods: {
    async loadCompany() {
      this.loading = true
      try {
        const response = await ConfigurationApi.getCompanyConfiguration()
        this.configurationCompanyProfile = response.data
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      this.loading = true
      try {
        await ConfigurationApi.editCompanyConfiguration(this.configurationCompanyProfile)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
