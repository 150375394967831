<template>
  <BaseModal
    v-model="model"
    size="lg"
    centered
    :showFooter="false"
  >
    <h1 class="font-large-1 text-center pt-2">
      Añadir provision
    </h1>
    <validation-observer ref="provision-form">
      <b-form @submit.prevent class="px-4">
        <b-row align-h="center">
          <b-col cols="12">
            <validation-provider
              v-slot="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group label="Nombre" label-for="provision-name">
                <b-form-input
                  id="provision-name"
                  v-model="form.name"
                  placeholder="Nombre"
                  type="text"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            
              <b-form-group label="Categoría" label-for="provision-category">
                <BaseSelect
                  id="provision-category"
                  :value="form.category"
                  :resource="
                    $data.$constants.RESOURCES.RESOURCE_PROVISION_CATEGORIES
                  "
                  :clearable="false"
                  @input="form.category = $event"
                />
                <small v-if="errorSelectCategory" class="text-danger">
                  Selecciona una Categoría
                </small>
              </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-row align-h="center" class="py-2">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleConfirmButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
    </validation-observer>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import ProvisionsApi from "@/api//provisions-api"

export default {
  neme: "ProvisionsCreationModal",
  components: {
    BaseModal,
    BaseSelect,
  },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        category: {},
      },
      errorSelectCategory: false
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.resetValues()
        this.$emit("input", value);
      },
    },
  },
  methods: {
    resetValues () {
      this.form = {
        name: "",
        category: {},
      }
      this.errorSelectCategory = false
    },
    validateSelectedCategory () {
      const isValid = Object.keys(this.form.category).length > 0
    
      this.errorSelectCategory = !isValid

      return isValid
    },
    async handleConfirmButtonClick() {
      const validCategory = this.validateSelectedCategory()
      const valid = await this.$refs["provision-form"].validate() && validCategory;

      if (!valid) {
        return;
      }

      this.loading = true;
      try {
        const data = {
          name: this.form.name,
          provision_category_id: this.form.category.id
        }
        await ProvisionsApi.create(data, this.$data.$constants.RESOURCES.RESOURCE_PROVISIONS)
        this.$emit('provisionCreated')
        this.$emit('input', false)
        this.resetValues()
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
