<template>
  <b-overlay
    :show="loading"
    variant="white"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-table
      hover
      responsive
      :items="bankAccounts"
      :fields="fields"
      :no-local-sorting="true"
    >
      <template #cell(name)="data">
        <b-form-input
          v-model="data.item.name"
          @change="handleBankAccountUpdate( data.item)"
        />
      </template>
      <template #cell(actions)="data">
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_CONFIGURATION,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
        >
          <span
            class="d-inline-block px-50 text-danger"
            @click="handleDeleteButtonClick(data.item)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </b-link>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import ApiRestService from '@/api/base-api'

export default {
  name: 'BankAccountsTable',
  data() {
    return {
      loading: false,
      bankAccounts: [],
      fields: [
        {
          label: 'CUENTA',
          key: 'name',
          sortable: false,
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  mounted() {
    this.loadBankAccounts()
  },
  methods: {
    async loadBankAccounts() {
      this.loading = true
      try {
        const response = await ApiRestService.list({}, this.$data.$constants.RESOURCES.RESOURCE_BANK_ACCOUNTS)
        this.bankAccounts = response.data
      } finally {
        this.loading = false
      }
    },
    async handleBankAccountUpdate(bankAccount) {
      this.loading = true
      try {
        await ApiRestService.edit(bankAccount.id, bankAccount, this.$data.$constants.RESOURCES.RESOURCE_BANK_ACCOUNTS)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteButtonClick(bankAccount) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(bankAccount.id, this.$data.$constants.RESOURCES.RESOURCE_BANK_ACCOUNTS)
        await this.loadBankAccounts()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
