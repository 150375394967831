<template>
  <validation-observer ref="configuration-company-profile-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Nombre"
                label-for="company-name"
              >
                <b-form-input
                  id="company-name"
                  :value="model.name"
                  placeholder="Nombre"
                  @input="update('name', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="CIF"
                label-for="company-cif"
              >
                <b-form-input
                  id="company-cif"
                  :value="model.cif"
                  placeholder="CIF"
                  @input="update('cif', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Teléfono"
                label-for="company-phone"
              >
                <b-form-input
                  id="company-phone"
                  :value="model.phone"
                  placeholder="Teléfono"
                  @input="update('phone', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Email"
                label-for="company-email"
              >
                <b-form-input
                  id="company-email"
                  :value="model.email"
                  placeholder="Email"
                  @input="update('email', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Dirección"
                label-for="company-direction"
              >
                <b-form-input
                  id="company-direction"
                  :value="model.direction"
                  placeholder="Dirección"
                  @input="update('direction', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Web"
                label-for="company-web"
              >
                <b-form-input
                  id="company-web"
                  :value="model.web"
                  placeholder="Web"
                  @input="update('web', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col lg="6">
              <b-form-group
                label="País"
                label-for="company-country"
              >
                <b-form-input
                  id="company-country"
                  :value="model.country"
                  placeholder="País"
                  @input="update('country', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Provincia"
                label-for="company-province"
              >
                <b-form-input
                  id="company-province"
                  :value="model.province"
                  placeholder="Provincia"
                  @input="update('province', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Ciudad"
                label-for="company-town"
              >
                <b-form-input
                  id="company-town"
                  :value="model.town"
                  placeholder="Ciudad"
                  @input="update('town', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Código postal"
                label-for="company-cp"
              >
                <b-form-input
                  id="company-cp"
                  :value="model.cp"
                  placeholder="Código postal"
                  @input="update('cp', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Divisa"
                label-for="company-currency"
              >
                <b-form-input
                  id="company-currency"
                  :value="model.currency"
                  placeholder="Divisa"
                  @input="update('currency', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'

export default {
  name: 'ConfigurationCompanyProfileForm',
  mixins: [FormMixin],
  methods: {
    validateForm() {
      return this.$refs['configuration-company-profile-form'].validate()
    },
  },
}
</script>

<style scoped>

</style>
