<template>
  <b-row>
    <b-col cols="12">
      <ConfigurationCompanyProfileCard />
    </b-col>
    <b-col cols="12">
      <ConfigurationTypePaymentsCard />
    </b-col>
    <b-col cols="12">
      <ConfigurationTaxesCard />
    </b-col>
    <b-col lg="6">
      <ConfigurationBankAccountsCard />
    </b-col>
    <b-col lg="6">
      <ConfigurationAccountingAccountsCard />
    </b-col>
    <b-col lg="12">
      <ConfigurationSaleInvoiceTypesCard />
    </b-col>
    <b-col lg="12">
      <p class="text-uppercase ml-1">Preventivos Mantenimiento</p>
    </b-col>
    <b-col lg="12" class="ma-2">
      <ConfigurationProvisionsCard />
    </b-col>
    <b-col lg="6" class="ma-2">
      <ConfigurationProvisionCategoriesCard />
    </b-col>
    <b-col lg="12">
      <ConfigurationAditionalCard />
    </b-col>
  </b-row>
</template>

<script>

import ConfigurationCompanyProfileCard from '@/components/configuration/card/ConfigurationCompanyProfileCard.vue'
import ConfigurationBankAccountsCard from '@/components/configuration/card/ConfigurationBankAccountsCard.vue'
import ConfigurationTypePaymentsCard from '@/components/configuration/card/ConfigurationTypePaymentsCard.vue'
import ConfigurationTaxesCard from '@/components/configuration/card/ConfigurationTaxesCard.vue'
import ConfigurationSaleInvoiceTypesCard from '@/components/configuration/card/ConfigurationSaleInvoiceTypesCard.vue'
import ConfigurationProvisionsCard from '@/components/configuration/card/ConfigurationProvisionsCard.vue'
import ConfigurationProvisionCategoriesCard from '@/components/configuration/card/ConfigurationProvisionCategoriesCard.vue'
import ConfigurationAditionalCard from '@/components/aditional-configuration/card/ConfigurationAditionalCard.vue'
import ConfigurationAccountingAccountsCard from '@/components/configuration/card/ConfigurationAccountingAccountsCard.vue'

export default {
  components: {
    ConfigurationSaleInvoiceTypesCard,
    ConfigurationTaxesCard,
    ConfigurationTypePaymentsCard,
    ConfigurationBankAccountsCard,
    ConfigurationCompanyProfileCard,
    ConfigurationProvisionsCard,
    ConfigurationProvisionCategoriesCard,
    ConfigurationAditionalCard,
    ConfigurationAccountingAccountsCard
  },
}
</script>
