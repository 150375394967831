import { BaseApi } from "@/api/base-api";
import { RESOURCE_PROVISION_CATEGORIES } from "@/shared/constants/resources";

class ProvisionCategoriesApi extends BaseApi {
  constructor(resource) {
    super(resource);
    this.resource = resource;
  }
}

export default new ProvisionCategoriesApi(RESOURCE_PROVISION_CATEGORIES);