<template>
  <BaseCard
    title="cuentas contables"
    :footer-visible="false"
    actions-visible
    collapsable
    :initial-collapse="false"
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir cuenta</u>
      </b-link>
    </template>
    <AccountingAccountsTable ref="accountings-accounts-table" />
    <AccountingAccountsCreationModal 
      v-model="creationModalVisible"
      @created="accountingsAccountsTable.loadData()"
    />
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/ui/card/BaseCard.vue";
import AccountingAccountsCreationModal from "@/components/accounting-accounts/modal/AccountingAccountsCreationModal.vue"
import AccountingAccountsTable from '@/components/accounting-accounts/table/AccountingAccountsTable.vue'

export default {
  name: "ConfigurationAccountingAccountsCard",
  components: { BaseCard, AccountingAccountsCreationModal, AccountingAccountsTable },
  data() {
    return {
      creationModalVisible: false,
    }
  },
  computed: {
    accountingsAccountsTable() {
      return this.$refs['accountings-accounts-table']
    },
  },
};
</script>
