<template>
  <BaseModal
    v-model="model"
    size="lg"
    centered
    :showFooter="false"
  >
    <h1 class="font-large-1 text-center pt-2">
      Añadir categoria provision
    </h1>
    <validation-observer ref="category-provision-form">
      <b-form @submit.prevent class="px-4">
        <b-row align-h="center">
          <b-col cols="12">
            <validation-provider
              v-slot="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group label="Nombre" label-for="category-provision-name">
                <b-form-input
                  id="category-provision-name"
                  v-model="name"
                  placeholder="Nombre"
                  type="text"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <b-row align-h="center" class="py-2">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleConfirmButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
    </validation-observer>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import ProvisionCategoriesApi from '@/api/provision-categories-api'

export default {
  name: 'ProvisionCategoryCreationModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      name: ''
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.resetValues()
      },
    },
  },
  methods: {
    resetValues () {
      this.name = null
    },
    async handleConfirmButtonClick() {
      const valid = await this.$refs["category-provision-form"].validate();

      if (!valid) {
        return;
      }

      this.loading = true;
      try {
        await ProvisionCategoriesApi.create({ name:this.name }, this.$data.$constants.RESOURCES.RESOURCE_PROVISION_CATEGORIES)
        this.$emit('updateCategories')
        this.$emit('input', false)
        this.resetValues()
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>