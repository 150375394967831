<template>
  <BaseCard
    title="Provisiones"
    :footer-visible="false"
    :loading="loading"
    actions-visible
    collapsable
    :initial-collapse="false"
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir provision</u>
      </b-link>
    </template>
    <ProvisionsTable ref="provisions-table" />
    <ProvisionsCreationModal 
      v-model="creationModalVisible" 
      @provisionCreated="$refs['provisions-table'].loadProvisions()"
    />
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/ui/card/BaseCard.vue";
import ProvisionsTable from "@/components/provisions/table/ProvisionsTable.vue"
import ProvisionsCreationModal from "@/components/provisions/modal/ProvisionsCreationModal.vue"

export default {
  components: { 
    BaseCard,
    ProvisionsTable,
    ProvisionsCreationModal
  },
  data() {
    return {
      loading: false,
      creationModalVisible: false,
    };
  },
};
</script>
