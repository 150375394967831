<template>
  <BaseCard
    title="Categorias de provision"
    :footer-visible="false"
    :loading="loading"
    actions-visible
    collapsable
    :initial-collapse="false"
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir Categoria</u>
      </b-link>
    </template>
    <ProvisionCategoriesTable ref="categories-table" />
    <ProvisionCategoryCreationModal 
      v-model="creationModalVisible"
      @updateCategories="$refs['categories-table'].loadCategories()"
    />
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/ui/card/BaseCard.vue";
import ProvisionCategoriesTable from "@/components/provision-categories/table/ProvisionCategoriesTable.vue"
import ProvisionCategoryCreationModal from "@/components/provision-categories/modal/ProvisionCategoryCreationModal.vue"

export default {
  components: { 
    BaseCard,
    ProvisionCategoriesTable,
    ProvisionCategoryCreationModal,
  },
  data() {
    return {
      loading: false,
      creationModalVisible: false,
    };
  },
}
</script>