<template>
  <BaseTable
    ref="sale-invoice-types-table"
    :resource="$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_TYPES"
    disable-pagination
    hide-toolbar
    :columns="columns"
    :loading="loading"
  >
    <template #cell()="data">
      <b-form-input
        v-model="data.item[data.field.key]"
        @change="handleSaleInvoiceTypeUpdate(data.item)"
      />
    </template>
    <template #cell(actions)="data">
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_CONFIGURATION,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
      >
        <span
          class="d-inline-block px-50 text-danger"
          @click="handleDeleteButtonClick(data.item)"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </span>
      </b-link>
    </template>
  </BaseTable>
</template>

<script>
import ApiRestService from '@/api/base-api'
import BaseTable from '@/components/ui/table/BaseTable.vue'

export default {
  name: 'SaleInvoiceTypesTable',
  components: { BaseTable },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'NOMBRE',
          key: 'name',
          sortable: false,
        },
        {
          label: 'OPERACIÓN',
          key: 'operation_type',
          sortable: false,
        },
        {
          label: 'CONCEPTO DE IMPUTACIÓN',
          key: 'concept_inputation_id',
          sortable: false,
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    saleInvoiceTypesTable() {
      return this.$refs['sale-invoice-types-table']
    },
  },
  methods: {
    loadSaleInvoiceTypes() {
      return this.saleInvoiceTypesTable.loadData()
    },
    async handleSaleInvoiceTypeUpdate(saleInvoiceType) {
      if (!saleInvoiceType) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.edit(saleInvoiceType.id, saleInvoiceType, this.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_TYPES)
        await this.loadSaleInvoiceTypes()
      } finally {
        this.loading = false
      }
    },
    async handleDeleteButtonClick(saleInvoiceType) {
      if (!saleInvoiceType) {
        return
      }

      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro.',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(saleInvoiceType.id, this.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_TYPES)
        await this.loadSaleInvoiceTypes()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
