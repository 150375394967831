<template>
  <b-overlay
    :show="loading"
    variant="white"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-table
      hover
      responsive
      :items="items"
      :fields="fields"
      :no-local-sorting="true"
    >
      <template #cell(order)="data">
        <b-form-input
          v-model="data.item.order"
          type="number"
          @change="handleUpdate(data.item)"
        />
      </template>
      <template #cell(name)="data">
        <b-form-input
          v-model="data.item.name"
          @change="handleUpdate( data.item)"
        />
      </template>
      <template #cell(actions)="data">
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_CONFIGURATION,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACCOUNTING_ITEMS
          }"
        >
          <span
            class="d-inline-block px-50 text-danger"
            @click="handleDeleteButtonClick(data.item)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </b-link>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import ApiRestService from '@/api/base-api'

export default {
  name: 'BankAccountsTable',
  data() {
    return {
      loading: false,
      items: [],
      fields: [
        {
          label: 'ORDEN',
          key: 'order',
          sortable: false,
          thStyle: { width: '100px' },
        },
        {
          label: 'CUENTA',
          key: 'name',
          sortable: false,
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      try {
        const response = await ApiRestService.list({}, this.$data.$constants.RESOURCES.RESOURCE_ACCOUNTING_ITEMS)
        this.items = response.data
      } finally {
        this.loading = false
      }
    },
    async handleUpdate(item) {
      this.loading = true
      try {
        await ApiRestService.edit(item.id, item, this.$data.$constants.RESOURCES.RESOURCE_ACCOUNTING_ITEMS)
        await this.loadData()
      } finally {
        this.loading = false
      }
    },
    async handleDeleteButtonClick(item) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(item.id, this.$data.$constants.RESOURCES.RESOURCE_ACCOUNTING_ITEMS)
        await this.loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>